import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Disclosure } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/solid"

const states = [
  {
    state: "Alabama",
    statute: "Ala.Code 1975 § 6-5-410",
    limitations: "2 Years",
    who: "Personal Representative",
    damages: [
      {
        type: "Punitive",
        description:
          "Alabama solely focused on the behavior of wrongdoer and not so much compensating a loss of injured parties.",
      },
    ],
  },
  {
    state: "Alaska",
    statute: "AS § 09.55.580.",
    limitations: "2 Years",
    who: "Personal Representative",
    damages: [
      {
        type: "Compensatory",
        description:
          "Deprivation of expectation of pecuniary benefits/Loss of contributions for support/loss of assistance/loss of consortium/loss of prospective training & education/medical & funeral expenses",
      },
    ],
  },
  {
    state: "Arizona",
    statute: "ARS § 12-611/612/613",
    limitations: "2 Years",
    who: "Personal Representative, surviving spouse, child, parent or guardian, decedents estate",
    damages: [
      { type: "Punitive", description: "Mitigating circumstances" },
      {
        type: "Compensatory",
        description:
          "very broad “Fair and just w/reference to injury. Includes financial dependency of the survivors/historical earnings of deceased & future earnings/Medical, Funeral, & Burial Costs/Value of emotional damage & Loss",
      },
    ],
  },
  {
    state: "Arkansas",
    statute: "A.C.A § 16-62-102",
    limitations: "3 Years",
    who: "Personal Representative (If none then heir at law of deceased person)",
    damages: [
      {
        type: "Compensatory",
        description:
          "2 Types of Claim: 1) Estate Claim = funeral & burial costs/medical bills/Pain & Suffering of deceased/loss of value of deceased’s life including wages he or she was likely to earn. 2) Family Claim = loss of financial support of deceased/loss of Household Services/Loss of care, comfort etc.",
      },
    ],
  },
  {
    state: "California",
    statute: "Cal.C.C.P § 377.60",
    limitations: "2 Years",
    who: "Personal Representative, Surviving spouse, domestic partner, Children or Surviving Issue",
    damages: [
      {
        type: "Compensatory",
        description:
          "Damages not in 377.34 (i.e. damages that would occur after death) financial support contributed by deceased/loss of gifts or benefits to heirs could have expected/Funeral & Burial Expenses/Companionship/Affection etc.",
      },
    ],
  },
  {
    state: "Colorado",
    statute: "C.R.S.A § 13-21-202",
    limitations: "2 Years",
    who: "1st Year – Spouse of deceased/heirs of deceased(by letter of spouse) 2nd Year – Spouse/heirs/designated beneficiary/Mother or Father(if unmarried & no designated beneficiaries)",
    damages: [
      {
        type: "Punitive",
        description:
          "Exemplary Damages taking into account aggravating circumstances.",
      },
      {
        type: "Compensatory",
        description:
          "Economic Loss (medical/funeral bills) & Noneconomic Loss (Pain & Suffering/emotional stress/loss of companionship etc.)",
      },
    ],
  },
  {
    state: "Connecticut",
    statute: "C.G.S.A § 52-555",
    limitations: "2 Years (some exceptions but no claims after 5 Years)",
    who: "Executor/Administrator",
    damages: [
      {
        type: "Punitive",
        description:
          "damages can be doubled or tripled according to C.G.S.A. § 14-295(when recklessly operating motor vehicle)",
      },
      {
        type: "Compensatory",
        description:
          "Economic (medical/funeral bills) & Noneconomic (Pain and Suffering/loss of enjoyment of life activities/loss of income)",
      },
    ],
  },
  {
    state: "Delaware",
    statute: "Del.C. § 3724",
    limitations: "2 Years",
    who: "a) Spouse, parent, child, & siblings of deceased b) If none who qualify under a),anyone related by blood or marriage.",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (reasonable funeral costs not more than $7k/loss of expectation of pecuniary benefits/loss of contributions for support/loss of parental, marital, & household services including cost of caring for minor child) & Noneconomic (mental anguish for beneficiaries under (a)",
      },
    ],
  },
  {
    state: "D.C.",
    statute: "DC ST § 16-2701",
    limitations: "2 Years",
    who: "Spouse/Domestic Partner (If none, then next of kin)",
    damages: [
      {
        type: "Compensatory",
        description:
          "(based on injury suffered by beneficiary from untimely loss of family) – Economic (funeral costs/medical costs/ lost wages and benefits/other contributions the deceased would likely have made)",
      },
    ],
  },
  {
    state: "Florida",
    statute: "F.S.A § 768.16-768.28",
    limitations: "2 Years (some exceptions)",
    who: "Personal Representative (If none then one is appointed by the court) – On behalf of spouse/children/parents/any blood relative or adoptive sibling who is partly or wholly dependent on decedent",
    damages: [
      {
        type: "Compensatory",
        description:
          "2 Types of Claim: 1) Estate Claim = lost wages & benefits deceased would’ve made/lost prospective net accumulations of estate/medical & funeral expenses paid by estate. 2) Family Claim = value of lost support & services/medical & funeral expenses/loss of companionship/mental and emotional Pain & Suffering.",
      },
    ],
  },
  {
    state: "Georgia",
    statute: "Ga. Code Ann., § 51-4-1 –> 51-4-6",
    limitations: "2 Years",
    who: "Surviving Spouse (if none) –> Surviving Children",
    damages: [
      {
        type: "Compensatory",
        description:
          "2 Types of Claim: 1) Estate Claim = funeral, medical, & other necessary expenses resulting from injury. 2) Family Claim = full value of life of deceased (lost wages, & benefits including what deceased might have reasonably earned/loss of care, companionship, & other intangible benefits)",
      },
    ],
  },
  {
    state: "Hawaii",
    statute: "HRS § 663-3",
    limitations: "2 Years",
    who: "Deceased’s Legal Representative, spouse, reciprocal beneficiary, children, parents, or any person wholly or partly dependent upon deceased",
    damages: [
      {
        type: "Compensatory",
        description:
          "2 Types of Claims: 1) Estate Claim = reasonable expenses of the deceased’s last illness and burial (funeral, burial, and medical costs/lost wages) 2) Family/Beneficiary Claims = loss of society, companionship, or comfort/loss of marital care, attention, or advice/loss of care/loss of filial care/loss of parental care, training, or education",
      },
    ],
  },
  {
    state: "Idaho",
    statute: "I.C § 5-311",
    limitations: "2 Years",
    who: "Personal Representative, heirs, spouse, children, stepchildren, parents, and those wholly or partially dependent on decendant",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, & burial/loss of financial support by family members who relied on deceased/loss of the reasonable value of household services deceased provided) & Noneconomic (loss of “care, comfort, and society”)",
      },
    ],
  },
  {
    state: "Illinois",
    statute: "740 ILCS 180/1",
    limitations: "1-2 Years",
    who: "Personal Representative, Spouse, Next of Kin",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic damages (medical, funeral, & burial costs) & Noneconomic (grief, sorrow, & mental suffering)",
      },
    ],
  },
  {
    state: "Indiana",
    statute: "IC 34-23-1-1",
    limitations: "2 Years",
    who: "Personal Representative MUST bring case (damages can be distributed to widow/widower/dependent children",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (medical, hospital, funeral, & burial costs –> Go to estate) (lost wages, & benefits etc. –> go to widow/widower/dependent children",
      },
    ],
  },
  {
    state: "Iowa",
    statute: "I.C.A § 611.20/611.21/611.22/633.336",
    limitations: "2 Years",
    who: "Personal Representative of Estate/spouse/children/parents",
    damages: [
      {
        type: "Compensatory",
        description:
          "Depends on who bring claims. Estate = medical/funeral expenses/lost wages.",
      },
    ],
  },
  {
    state: "Kansas",
    statute: "K.S.A. § 60-1901/1902/1903/1904/1905/1906",
    limitations: "2 Years (usually)",
    who: "“Heirs at Law of Deceased” (Usually means surviving spouse/children/parent/siblings)",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (funeral/burial/medical/lost wages/value of household services) & Noneconomic – capped at $250k (mental anguish/loss of society, companionship/loss of marital care, attention/loss of filial care attention/loss of parental care",
      },
    ],
  },
  {
    state: "Kentucky",
    statute: "KRS § 411.130",
    limitations: "1 Year",
    who: "Personal Representative (damages distributed to estate/spouse & children/parents)",
    damages: [
      {
        type: "Punitive",
        description: "“if act was willful or the negligence gross”",
      },
      {
        type: "Compensatory",
        description:
          "Economic (funeral/burial costs – Goes to Estate) & Noneconomic (loss of care/companionship, etc. – Goes to family)",
      },
    ],
  },
  {
    state: "Louisiana",
    statute: "LSA-C.C. Art. 2315.2",
    limitations: "1 Year",
    who: "Spouse/Children, if none –> Siblings, if none –> surviving grandparents",
    damages: [
      {
        type: "Punitive",
        description: "Some exceptions (ex. If injuries caused by DWI)",
      },
      {
        type: "Compensatory",
        description:
          "Economic (funeral/burial/medical bills) & Noneconomic (as to injury resulting from loss, i.e. loss of companionship etc.)",
      },
    ],
  },
  {
    state: "Maine",
    statute: "18-M.R.S.A § 2-804",
    limitations: "2 Years",
    who: "Personal Representative",
    damages: [
      { type: "Punitive", description: "capped at $250k" },
      {
        type: "Compensatory",
        description:
          "Economic (Medical/funeral/burial costs/loss of compensations/loss of expected wages) Noneconomic – capped at $500k (loss of comfort, society, companionship/Emotional Distress",
      },
    ],
  },
  {
    state: "Maryland",
    statute: "MD Code § 3-904",
    limitations: "3 Years",
    who: "Spouse/Parent/Children, if none –> Any person related by blood or marriage",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (Lost wages/medical, funeral, and burial costs) & Noneconomic (loss of society, comfort, marital care, guidance/Emotional Pain and suffering",
      },
    ],
  },
  {
    state: "Massachusetts",
    statute: "M.C.L.A § 2",
    limitations: "3 Years",
    who: "Executor/Administrator",
    damages: [
      {
        type: "Punitive",
        description:
          "not less than $5k if death caused by malicious/reckless conduct or gross negligence.",
      },
      {
        type: "Compensatory",
        description:
          "Economic (funeral, medical, burial costs/loss of expected wages) Noneconomic (loss of services/care/assistance/society/companionship/guidance",
      },
    ],
  },
  {
    state: "Michigan",
    statute: "M.C.L.A. § 600.2922",
    limitations: "3 Years",
    who: "Personal Representative",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (Medical, Funeral, burial costs/loss of financial support) Noneconomic (loss of companionship/Pain & Suffering of deceased)",
      },
    ],
  },
  {
    state: "Minnesota",
    statute: "M.S.A. § 573.02",
    limitations: "3 Years",
    who: "Trustee appointed by written petition by surviving spouse or one of the next of kin",
    damages: [
      { type: "Punitive", description: "Pursuant to § 549.20" },
      {
        type: "Compensatory",
        description:
          "Economic (reasonable funeral, burial, medical costs/loss of income, wages, benefits) & Noneconomic (sorrow, mental anguish)",
      },
    ],
  },
  {
    state: "Mississippi",
    statute: "Miss. Code Ann. § 11-7-13",
    limitations: "1 Year (intentional act), 3 Years (negligence)",
    who: "Surviving Spouse/Children/Siblings",
    damages: [
      {
        type: "Compensatory",
        description:
          "“such damages allowable by law as the jury may determine to be just, taking into consideration all the damages of every kind to the decedent and all damages of every kind to any and all parties interested in the suit.” (Medical/funeral/burial expenses)",
      },
    ],
  },
  {
    state: "Missouri",
    statute: "V.A.M.S. § 537.080",
    limitations: "3 Years",
    who: "Surviving Spouse, children, or grandchildren",
    damages: [
      { type: "Punitive", description: "Mitigating circumstances" },
      {
        type: "Compensatory",
        description:
          "Economic (funeral/medical bills) & Noneconomic (Loss of consortium/comfort/guidance)",
      },
    ],
  },
  {
    state: "Montana",
    statute: "MCA 27-1-513",
    limitations: "3 Years",
    who: "Personal Representative",
    damages: [
      {
        type: "Compensatory",
        description: "Economic & Non-Economic damages may be awarded",
      },
    ],
  },
  {
    state: "Nebraska",
    statute: "Neb.Rev.St. § 30-809",
    limitations: "2 Years",
    who: "Legal Representative of deceased’s estate",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, burial costs/lost compensation) & Noneconomic (pain and suffering/loss of companionship",
      },
    ],
  },
  {
    state: "Nevada",
    statute: "N.R.S. 41.085",
    limitations: "2 Years",
    who: "Heirs/Personal Representative of deceased",
    damages: [
      {
        type: "Punitive",
        description: "Estate Claim - Damages/medical/funeral costs.",
      },
      {
        type: "Compensatory",
        description:
          "Family Claim - Loss of support, companionship/pain & suffering of decendant",
      },
    ],
  },
  {
    state: "New Hampshire",
    statute: "N.H. Rev. Stat. § 556:19",
    limitations: "6 Years",
    who: "Any person interested in the estate of a person deceased may begin an action as administrator",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (Reasonable expense on estate/lost wages) & Noneconomic (Loss of comfort/companionship –> only for SPOUSE & max $150k/Loss of familial relationship –> only for children & max $50k/claimant)",
      },
    ],
  },
  {
    state: "New Jersey",
    statute: "N.J.S.A. 2A:31-1/31-2/31-3/31-4/31-5/31-6",
    limitations: "2 Years",
    who: "Administrator/Executor of Will (Damages go to Dependents & those inheriting from decendant)",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (Hospital, Medical, Funeral Expenses) & Noneconomic (Loss of companionship/support)",
      },
    ],
  },
  {
    state: "New Mexico",
    statute: "N.M.S.A. § 41-2-1/41-2-2/41-2-3/41-2-4",
    limitations: "3 Years",
    who: "Personal Representative (damages distributed to estate/spouse & children/parents/Siblings)",
    damages: [
      {
        type: "Punitive",
        description:
          "“having regard to the mitigating and aggravating circumstances,” & “the jury…may give such damages, compensatory and exemplary.”",
      },
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, burial expenses) & Noneconomic (Mental Anguish caused by death/Pain & Suffering deceased suffered/loss of companionship)",
      },
    ],
  },
  {
    state: "New York",
    statute: "EPTL § 5-4.1/5-4.2/5-4.3",
    limitations: "2 Years",
    who: "Personal Representative",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (Medical, Funeral, Burial costs/lost wages between final injury and death of deceased/Pain & Suffering of deceased/value of lost services)",
      },
    ],
  },
  {
    state: "North Carolina",
    statute: "N.C.G.S.A. § 28A-18-2",
    limitations: "2 Years",
    who: "Personal Representative",
    damages: [
      {
        type: "Punitive",
        description: "pursuant to Chap. 1D of General Statutes.",
      },
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, burial costs/present monetary value of decedent to persons entitled to receive damages –> loss of services/loss of net income) & Noneconomic (loss of society/companionship)",
      },
    ],
  },
  {
    state: "North Dakota",
    statute: "NDCC § 32-21-01/32-21-02/31-21-03/31-21-04/31-21-05/31-21-06",
    limitations: "3 Years",
    who: "Surviving Spouse/Children/Parents/Personal Representative/Person who had primary physical custody of decedent before wrongful act",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (Medical, funeral, burial costs/loss of earnings, income/loss of employment benefits) & Noneconomic (Pain& Suffering/Mental & Emotional Distress/Loss of consortium/loss of companionship)",
      },
    ],
  },
  {
    state: "Ohio",
    statute: "R.C. § 2125.01/2125.02/2124.03/2125.04",
    limitations: "2 Years",
    who: "Personal Representative (for benefit of: Spouse/Children/Parents)",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (Funeral, burial, medical costs/loss of financial support/loss of services) & Noneconomic (Mental Anguish/loss of companionship, care)",
      },
    ],
  },
  {
    state: "Oklahoma",
    statute: "12 Okl.St.Ann. § 1052/1053",
    limitations: "2 Years",
    who: "Personal Representative",
    damages: [
      {
        type: "Punitive",
        description: "pursuant to Sec. 9.1 of Title 23 of Oklahoma Statutes",
      },
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, burial costs/loss of financial support/loss of wages, benefits) & Noneconomic (loss of consortium/Pain & Suffering of decedent)",
      },
    ],
  },
  {
    state: "Oregon",
    statute: "O.R.S. § 30.020",
    limitations: "3 Years",
    who: "Personal Representative OR one who may benefit under such a claim (surviving spouse/children/parents)",
    damages: [
      { type: "Punitive", description: "in some cases" },
      {
        type: "Compensatory",
        description:
          "Economic (Medical, funeral, burial costs/loss of income, wages, benefits/loss of financial support) & Noneconomic (Pain & Suffering of decedent/Loss of companionship)",
      },
    ],
  },
  {
    state: "Pennsylvania",
    statute: "Pa.C.S.A. § 8301",
    limitations: "2 Years",
    who: "Spouse/Children/Parents/Personal representative",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, burial costs/lost wages, benefits) & Noneconomic (loss of companionship, society/pain & suffering of decedent)",
      },
    ],
  },
  {
    state: "Rhode Island",
    statute: "Gen. Laws 1956, § 10-7 (Chap. 7 – Death by Wrongful Act)",
    limitations: "3 Years",
    who: "Executor/Administrator (in benefit of spouse/children/parents)",
    damages: [
      { type: "Punitive", description: "pursuant to § 10-7-7.1" },
      {
        type: "Compensatory",
        description:
          "Economic (lost wages/loss of services to house/funeral, medical, burial costs) & Noneconomic (loss of consortium/loss of companionship/Pain & Suffering of decedent)",
      },
    ],
  },
  {
    state: "South Carolina",
    statute: "Code 1976 § 15-51-10",
    limitations: "3 Years",
    who: "Spouse/Children, if none –> Siblings, if none –> surviving parents",
    damages: [
      {
        type: "Punitive",
        description: "“if act was result of recklessness/malice”",
      },
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, burial costs/loss of wages, income, benefits) & Noneconomic (mental anguish & suffering/loss of companionship)",
      },
    ],
  },
  {
    state: "South Dakota",
    statute:
      "SDCL § 21-5-1/21-5-2/21-5-3/21-5-4/21-5-5/21-5-6/21-5-7/21-5-8/21-5-9",
    limitations: "3 Years",
    who: "Personal Representative (benefit of Spouse/Children/Parents",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (loss of value of household service/loss of value of financial support/medical, funeral, burial expenses/lost wages, benefits) & Noneconomic (loss of companionship, etc.)",
      },
    ],
  },
  {
    state: "Tennessee",
    statute: "T.C.A. § 20-5-106",
    limitations: "1 Year",
    who: "Personal Representative/Spouse/Children/Parents",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (funeral, burial, medical costs/loss of earning capacity during period of injury and death/loss of wages) & Noneconomic (Mental & Physical Pain and Suffering of deceased/loss of companionship)",
      },
    ],
  },
  {
    state: "Texas",
    statute: "V.T.C.A. § 71.002",
    limitations: "2 Years",
    who: "Spouse/Children/Parents/Executor/Administrator",
    damages: [
      {
        type: "Punitive",
        description: "“if death was caused by willful act or gross negligence”",
      },
      {
        type: "Compensatory",
        description:
          "Economic (lost earning capacity/lost care, services deceased would have provided) & Noneconomic (lost companionship/mental and emotional pain and suffering)",
      },
    ],
  },
  {
    state: "Utah",
    statute: "U.C.A. 1953 § 78B-3-106",
    limitations: "1-2 Years Depending",
    who: "Heirs/Personal Representative",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (funeral, burial, medical costs/lost wages) & Noneconomic (Pain & suffering of family members/loss of care, companionship)",
      },
    ],
  },
  {
    state: "Vermont",
    statute: "14 V.S.A § 1492",
    limitations: "2 Years",
    who: "Personal Representative",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (Lost wages/medical, funeral, burial costs/loss of household service, chores) & Noneconomic (loss of companionship)",
      },
    ],
  },
  {
    state: "Virginia",
    statute: "VA Code Ann. § 8.01-50",
    limitations: "2 Years",
    who: "Spouse/Children/Parent/Any heir",
    damages: [
      {
        type: "Punitive",
        description: "if there was willful or wanton conduct",
      },
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, burial costs/loss of income, services, benefits) & Noneconomic (sorrow, mental anguish, solace –> includes companionship, etc.)",
      },
    ],
  },
  {
    state: "Washington",
    statute: "RCWA 4.20.020",
    limitations: "3 Years",
    who: "Personal Representative (on behalf of spouse/children/parents)",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (medical, funeral, burial expenses/lost wages) & Noneconomic (pain & suffering of deceased/loss of care, companionship)",
      },
    ],
  },
  {
    state: "West Virginia",
    statute: "W.Va. Code § 55-7-5",
    limitations: "2 Years",
    who: "Personal Representative (on behalf of spouse/children/parents)",
    damages: [
      {
        type: "Compensatory",
        description:
          "Economic (loss of income/medical, funeral, burial costs) & Noneconomic (loss of services, protection, care, assistance/sorrow, mental anguish, solace –> includes companionship, guidance, etc.)",
      },
    ],
  },
  {
    state: "Wisconsin",
    statute: "W.S.A 895.03",
    limitations: "3 Years",
    who: "Personal Representative",
    damages: [
      { type: "Punitive", description: "pursuant to W.S.A. 895.043" },
      {
        type: "Compensatory",
        description:
          "Economic (medical, burial, funeral costs/loss of income, financial losses etc.) & Noneconomic (Loss of society, companionship –>max $350k for deceased adult/max $500k for deceased child)",
      },
    ],
  },
  {
    state: "Wyoming",
    statute: "W.S.1977 § 1-38-102",
    limitations: "2 Years",
    who: "Personal Representative (on behalf of beneficiaries who have sustained damage)",
    damages: [
      { type: "Punitive", description: "“as deemed fair & just”" },
      {
        type: "Compensatory",
        description:
          "Economic (funeral, burial, medical cost/loss of wages, income) & Noneconomic (pain &suffering of deceased/loss of companionship)",
      },
    ],
  },
]

const Page = () => {
  const metadata = {
    title: "WRONGFUL DEATH LAW INFORMATION BY STATE",
    description:
      "Find information about wrongful death laws by state. Our comprehensive resource provides valuable information for those seeking information about their legal rights and options.",
    heroH1: "WRONGFUL DEATH LAW INFORMATION BY STATE",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Wrongful Death Information by State:</h2>
            <h3>Click a State For More Information</h3>

            <div className="w-full">
              <div className="w-full mx-auto bg-white rounded-2xl">
                {states.map(state => (
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-white bg-gray-900 rounded-lg hover:bg-gray-500 mb-2">
                          <span>{state.state}</span>
                          <ChevronUpIcon
                            className={`${
                              open ? "transform rotate-180" : ""
                            } w-5 h-5 text-green-400`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-900">
                          <div className="grid grid-rows-auto auto-rows-min">
                            <div className="grid grid-cols-2 my-0">
                              <strong>Statute:</strong>
                              <span>{state.statute}</span>
                            </div>
                            <div className="grid grid-cols-2">
                              <strong>Statute of Limitations:</strong>
                              <span>{state.limitations}</span>
                            </div>
                            <div className="grid grid-cols-2">
                              <strong>Who Can Bring Claim:</strong>
                              <span>{state.who}</span>
                            </div>
                            <div className="grid grid-cols-2">
                              <strong>Damages Available</strong>
                              <p>
                                {state.damages.map(damages => (
                                  <p>
                                    <strong>{damages.type}</strong> -{" "}
                                    {damages.description}
                                  </p>
                                ))}
                              </p>
                            </div>
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
              <p>
                <a href="/ct-wrongful-death/">
                  For more information from our wrongful death attorney please
                  click here
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
